// import Image from 'next/image'
import dynamic from 'next/dynamic'

import { PageLayout } from 'components/layouts'
import { Loader, Section, Image } from 'components/common'

const MainBanner = dynamic(() => import('components/Banner'), { loading: () => <Loader place={true} /> })

const MainPage = () => {
	return (
		<PageLayout slug="main">
			<MainBanner />

			<Section className="block-about">
				<span className="target" id="about" />
				<div className="heading">
					<h2>About us</h2>
					<p>
						BIOCAD HONG KONG is an established company which is involved in R&D, business development, marketing and commercialising of biosimilars and innovative pharmaceuticals
						for the treatment of oncological, autoimmune, and orphan diseases.
					</p>
				</div>
				<div className="row content">
					<div className="biocad">
						<Image src="/images/about.png" alt="Biocad" width="420" height="204" layout="responsive" />
					</div>
					<div className="desc">
						<p>
							Our aim is to be flexible to suit your business needs, therefore we provide several kinds of opportunities including licensing, contract manufacturing and technology
							transfer.
						</p>
					</div>
				</div>
			</Section>

			<Section className="block-export">
				<span className="target" id="export" />
				<div className="heading">
					<h2>Export destinations</h2>
					<p>The company operates in Latin America, South-East Asia, Middle East and Africa regions</p>
				</div>
				<div className="content">
					<ul>
						<li>Bangladesh</li>
						<li>Bolivia</li>
						<li>Cambodia</li>
						<li>Cote D'Ivoire</li>
						<li>Dominican Republic</li>
						<li>Guatemala</li>
						<li>Honduras</li>
						<li>India</li>
						<li>Iraq</li>
						<li>Kenya</li>
						<li>
							Mexico <span>(Technology Transfer)</span>
						</li>
						<li>
							Morocco <span>(Technology Transfer)</span>
						</li>
						<li>Nicaragua</li>
						<li>Panama</li>
						<li>Pakistan</li>
						<li>Philippines</li>
						<li>Senegal</li>
						<li>
							Sri Lanka <span>(Technology Transfer)</span>
						</li>
						<li>Uganda</li>
						<li>Vietnam</li>
						<li>Yemen</li>
					</ul>
				</div>
			</Section>

			<Section className="block-looking">
				<span className="target" id="looking" />
				<div className="heading">
					<h2>What we are looking for</h2>
				</div>

				<div className="row content">
					<div className="item">
						<span className="icon ic1" />
						<div className="title">Supply chain partners</div>
						<div className="desc">We are looking for suppliers with quality products and services and open for all kinds of opportunities.</div>
					</div>
					<div className="item">
						<span className="icon ic2" />
						<div className="title">Contract manufacturing</div>
						<div className="desc">
							BIOCAD HONG KONG has a potential to being involved in contract manufacturing processes: from early development and commercial-scale manufacturing for biological and
							chemical drug products to sales, marketing and promotion assistance.
						</div>
					</div>
					<div className="item">
						<span className="icon ic3" />
						<div className="title">In-licensing and out-licensing</div>
						<div className="desc">
							BIOCAD HONG KONG has developed an extensive dynamic network of partners introducing our products to multiple markets. For those who are ready to join it we can
							suggest ambitious and fulfilling opportunity to quickly expand their portfolio. And in case you would like to introduce your product to markets of our presence, we
							would be ready to assist you.
						</div>
					</div>
					<div className="item">
						<span className="icon ic4" />
						<div className="title">International business development</div>
						<div className="desc">
							Currently we are looking for partners with an expertise in oncology, dermatology and rheumatology to market and distribute our original biologic products prolgolimab
							(PD-1 inhibitor), netakimab (IL-17 inhibitor), levilimab (aIL6R), oncology biosimilars and generics in both highly-regulated and developing markets
						</div>
					</div>
				</div>
			</Section>

			<Section className="block-contacts">
				<span className="target" id="contacts" />
				<div className="heading">
					<h2>Contacts</h2>
					<p>Please do not hesitate to ask any question regarding business collaboration and get more detailed information about BIOCAD HONG KONG activities</p>
				</div>
				<div className="row content">
					<div className="row planet">
						<h3>Offices of BIOCAD HONG KONG are located in China and the UAE</h3>
					</div>
					<div className="desc">
						<div className="line">
							<h3>Branch of BIOCAD HONG KONG LIMITED in Dubai</h3>
							<p>
								<span className="head lb">Business development activities.</span> Premises 704N, floor 07, Dubai Science Park Towers - North, Dubai, UAE
							</p>
						</div>
						<div className="line">
							<h3 className="head">BIOCAD HONG KONG LIMITED</h3>
							<p>
								<span className="lb">Procurement activities.</span> Room 309, Building 3, Daerwen Road 88, Pudong new area, Shanghai, China
							</p>
						</div>

						<div className="address" id="writetous">
							<div className="line">
								<p>
									<span>Legal address:</span> Suite 1801, Chinachem Exchange Square, 1 Hoi Wan Street, Quarry Bay, Hong Kong
								</p>
							</div>
							<div className="line">
								<p>
									<span>Email: </span>
									<a href="mailto:info@biocadhk.com">info@biocadhk.com</a>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row bottom">
					<p>The site is currently under construction, new information coming soon. If you have any questions concerning potential partnership, please go to contacts section.</p>
				</div>
			</Section>
		</PageLayout>
	)
}

export default MainPage
